import React from "react";
import { NavigationRouter } from "xa-navigation";
import { Switch, withRouter, RouteComponentProps } from "react-router-dom";
import ProjectsController from "./components/Projects/Controller/ProjectsController";
import ProjectController from "./components/Layout/Controller/ProjectController";
import LoginController from "./components/Login/Controller/LoginController";
import Unauthorized from "./components/Unauthorized/Unauthorized";

const App: React.FC<RouteComponentProps> = (props) => {
    return (
        <Switch>
            <NavigationRouter path="/login" component={LoginController} exact={true} />
            <NavigationRouter
                path="/project/:id"
                component={ProjectController}
                requiredLevel={[1, 2]}
                exact={true}
            />
            <NavigationRouter
                path="/"
                component={ProjectsController}
                requiredLevel={[1, 2]}
                exact={true}
            />
            <NavigationRouter path="/unauthorized" component={Unauthorized} exact />
        </Switch>
    );
};

export default withRouter(App);
