import * as React from "react";
import SideBar from "../View/SideBar";
import PageController from "../../Page/Controller/PageController";
import PagesDAO from "../DAO/PagesDAO";
import PagesModel, { IProject } from "../Model/PagesModel";

import { withRouter } from "react-router-dom";
import { IRouterProps } from "xa-navigation";

interface IProjectProps {
    history: any;
    location: any;
    match: any;
    staticContext?: any;
    computedMatch: any;
}

interface IProjectState {
    isSideBarOpen: boolean;
    data: PagesModel;
    loading: boolean;
    error: any;
    selectedID: string | null;
    wantedID: string | null;
    unSaved: boolean;
    modal: boolean;
    showDeleteModal: boolean;
    deleteValue: string;
    idToDelete: number;
    pageOrGroup: string;
}

class ProjectController extends React.Component<IProjectProps, IProjectState> {
    state = {
        isSideBarOpen: false,
        data: new PagesModel(),
        loading: true,
        error: null,
        selectedID: null,
        wantedID: null,
        unSaved: false,
        modal: false,
        showDeleteModal: false,
        deleteValue: "",
        idToDelete: 0,
        pageOrGroup: ""
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        PagesDAO.getPages(this.props.computedMatch.params.id)
            .then((response) => {
                let selectedID: string | null = null;
                for (let i = 0; i < response.pageGroups.length; i++) {
                    let flag: boolean = false;
                    for (let j = 0; j < response.pageGroups[i].pagesArray.length; j++) {
                        if (response.pageGroups[i].pagesArray[j].pageID !== null) {
                            selectedID = response.pageGroups[i].pagesArray[j].pageID.toString();
                        }
                    }
                    if (flag) {
                        break;
                    }
                }
                this.setState({ selectedID: selectedID, data: response, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false, error });
            });
    };

    //saveModal
    setModal = (value: boolean) => {
        this.setState({ modal: value });
    };

    setUnSaved = (value: boolean) => {
        this.setState({ unSaved: value });
    };

    openSideBar = () => {
        this.setState({ isSideBarOpen: true });
    };

    closeSideBar = () => {
        this.setState({ isSideBarOpen: false });
    };

    selectPageID = (pageID: string | null) => {
        if (pageID !== this.state.selectedID) {
            if (!this.state.unSaved) {
                //nem kell menteni
                this.setState({ modal: false, selectedID: pageID });
                this.closeSideBar();
            } else {
                //menteni kell
                this.setState({ modal: true, wantedID: pageID });
                this.closeSideBar();
            }
        }
    };

    setPageID = () => {
        this.setState({ selectedID: this.state.wantedID });
    };

    //deleteModal
    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false, deleteValue: "" });
    };

    deleteModalInputChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ deleteValue: event.currentTarget.value });
    };

    deleteButton = () => {
        if (this.state.deleteValue === "DELETE") {
            if (this.state.pageOrGroup === "page") {
                PagesDAO.deletePage(this.state.idToDelete.toString())
                    .then((response) => {
                        this.setState({ loading: true, error: null }, () => {
                            this.getData();
                        });
                    })
                    .catch((error) => {
                        this.setState({ loading: false, error });
                    });
            } else if (this.state.pageOrGroup === "group") {
                PagesDAO.deleteGroup(this.state.idToDelete)
                    .then((response) => {
                        this.setState({ loading: true, error: null }, () => {
                            this.getData();
                        });
                    })
                    .catch((error) => {
                        this.setState({ loading: false, error });
                    });
            }
            this.closeDeleteModal();
        }
    };

    deletePageButton = (pageID: number) => {
        this.setState({ pageOrGroup: "page", idToDelete: pageID, showDeleteModal: true });
    };

    deleteGroupButton = (groupID: number) => {
        this.setState({ pageOrGroup: "group", idToDelete: groupID, showDeleteModal: true });
    };

    newPageCreated = () => {
        this.getData();
    };
    newGroupCreated = () => {
        this.getData();
    };

    render() {
        return (
            <div>
                <SideBar
                    error={this.state.error}
                    loading={this.state.loading}
                    deleteModalInputChangeHandler={this.deleteModalInputChangeHandler}
                    showDeleteModal={this.state.showDeleteModal}
                    deleteValue={this.state.deleteValue}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteButton={this.deleteButton}
                    selectedID={this.state.selectedID}
                    project={this.state.data}
                    isSideBarOpen={this.state.isSideBarOpen}
                    openSideBar={this.openSideBar}
                    closeSideBar={this.closeSideBar}
                    selectPageID={this.selectPageID}
                    deletePageButton={this.deletePageButton}
                    newPageCreated={this.newPageCreated}
                    deleteGroupButton={this.deleteGroupButton}
                    newGroupCreated={this.newGroupCreated}
                />
                <PageController
                    modal={this.state.modal}
                    unSaved={this.state.unSaved}
                    selectedID={this.state.selectedID}
                    setPageID={this.setPageID}
                    setUnSaved={this.setUnSaved}
                    setModal={this.setModal}
                />
            </div>
        );
    }
}
export default withRouter(ProjectController);
