import React from "react";
import ReactDOM from "react-dom";
import Environment from "./Models/Environment.model";
import DefaultAxios, { AxiosResponse, AxiosError } from "axios";
import { axios, ErrorSettings, IDynamicObject } from "xa-generics";
import { AccessFactory, ApplicationAccess } from "access-factory";
import { EnvironmentContext } from "./Context/Environment.context";
import { BrowserRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Errors } from "./static/Errors.static";
import * as serviceWorker from "./serviceWorker";
import * as AccessLevels from "./static/AccessLevels.static";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

ErrorSettings.setCodes(Errors);
AccessFactory.initialize(AccessLevels);

DefaultAxios.get("/api/rest")
    .then((response: AxiosResponse<IDynamicObject>) => new Environment(response.data))
    .then((ENV_KEYS: Environment) => {
        axios.initAxios(ENV_KEYS.REST_API_URL);

        const AppRoot = (
            <BrowserRouter>
                <EnvironmentContext.Provider value={ENV_KEYS}>
                    <ApplicationAccess Spinner={<Spinner />}>
                        <App />
                    </ApplicationAccess>
                </EnvironmentContext.Provider>
            </BrowserRouter>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Error:</p>
                <p>{error.message}</p>
            </div>,
            document.getElementById("root")
        );
    });

serviceWorker.unregister();
