import * as React from "react";
import ParamView from "./ParamView";
import { ValueType } from "react-select";
import { IParametersType } from "../../Page/Model/PageModel";
import { ITypeOption } from "../Static/TypeOptions";
import { IModeOption } from "../Static/ModeOptions";
import "./Less/Params.css";

interface IProps {
    array: IParametersType[];
    arrayTitle: string;
    deleteParameter: (index: number) => void;
    paramSelectTypeChangeHandler: (index: number, event: ValueType<ITypeOption>) => void;
    paramSelectModeChangeHandler: (index: number, event: ValueType<IModeOption>) => void;
    paramNameChangeHandler: (index: number, event: React.FormEvent<HTMLInputElement>) => void;
    paramDescriptionChangeHandler: (
        index: number,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
}

const parametersView: React.FC<IProps> = (props) => {
    let arrayTitle: string = "";
    switch (props.arrayTitle) {
        case "path":
            arrayTitle = "Path Parameters";
            break;
        case "header":
            arrayTitle = "Headers";
            break;
        case "query":
            arrayTitle = "Query Parameters";
            break;
        case "form":
            arrayTitle = "Form Data Parameters";
            break;
        case "body":
            arrayTitle = "Body Parameters";
            break;
    }

    let pathTitle = <p className="parameters-block__paragraph-title">{arrayTitle}</p>;

    return (
        <div className="parameters-block">
            {pathTitle}
            <div className="parameters-block__array-of-params">
                {props.array.map((item, index) => (
                    <ParamView
                        key={index}
                        name={item.parameterName}
                        type={item.parameterSelectedType}
                        description={item.parameterDescription}
                        mode={item.parameterSelectedMode}
                        deleteParameter={() => props.deleteParameter(index)}
                        selectTypeChangeHandler={(event) =>
                            props.paramSelectTypeChangeHandler(index, event)}
                        selectModeChangeHandler={(event) =>
                            props.paramSelectModeChangeHandler(index, event)}
                        nameChangeHandler={(event) => props.paramNameChangeHandler(index, event)}
                        descriptionChangeHandler={(event) =>
                            props.paramDescriptionChangeHandler(index, event)}
                    />
                ))}
            </div>
        </div>
    );
};

export default parametersView;
